import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";



const GasCost = ({ details, city_names, filtered, reportID, musts, assumptions, variableCosts }) => {

    const [loading, setLoading] = useState(true);
    const [yearToShow, setYearToShow] = useState(null);
    
    useEffect(() => {
        let yearRef = 0
        variableCosts.forEach(city => {
            const year = city?.dataVariables?.gasCost?.year
            if (year && year > yearRef) {
                yearRef = year;
            }
        })
        setYearToShow(yearRef);
        
        if (musts) {
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {loading ?
                (
                    <div> Loading</div>
                ) : (
                    musts ?
                        (
                            <>
                                <Row className="no-gutters d-none d-lg-flex">
                                    <Col lg={3} className="fixed-container">
                                        <h5 className="first-spacer">Concept</h5>
                                        <h5 className="side-text">Subtotal MXP Monthly</h5>
                                        <h5 className="side-text">VAT</h5>
                                        <h5 className="side-text">Total MXP Monthly</h5>
                                        <h5 className="side-text">Estimated USD Monthly</h5>
                                        <h5 className="side-text">Estimated USD Annual Cost</h5>
                                        <h5 className="side-text">Difference from lowest value</h5>
                                    </Col>
                                    <Col lg={9} className="table-container">
                                        <div className="table-header">
                                            {city_names.map((city, index) =>
                                                filtered.includes(index) &&
                                                <h5 className="city-title" key={`table1 ${index}`}>{city}</h5>
                                            )}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let subtotal = variableCosts?.find(cityFind => cityFind.cityName === city);
                                                if (subtotal) {
                                                    let subTotalAccess = subtotal.dataVariables?.gasCost?.monthlyMxSubCost;
                                                    if (subTotalAccess) {
                                                        infoToShow = formatNumber(subTotalAccess);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`row1 ${index}`}>$ {infoToShow}</h5>
                                            })}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let vat = variableCosts?.find(cityFind => cityFind.cityName === city);
                                                if (vat) {
                                                    let vatAccess = vat.dataVariables?.gasCost?.vat;
                                                    if (vatAccess) {
                                                        infoToShow = formatNumber(vatAccess);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`row2 ${index}`}>$ {infoToShow}</h5>
                                            })}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let total = variableCosts?.find(cityFind => cityFind.cityName === city);
                                                if (total) {
                                                    let totalAccess = total.dataVariables?.gasCost?.monthlyMxCost;
                                                    if (totalAccess) {
                                                        infoToShow = formatNumber(totalAccess);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`row3 ${index}`}>$ {infoToShow}</h5>
                                            })}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let monthlyCostUSD = variableCosts?.find(cityFind => cityFind.cityName === city);
                                                if (monthlyCostUSD) {
                                                    let monthlyUSD = monthlyCostUSD.dataVariables?.gasCost?.monthlyCost;
                                                    if (monthlyUSD) {
                                                        infoToShow = formatNumber(monthlyUSD);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`row4 ${index}`}>$ {infoToShow}</h5>
                                            })}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let annualUSD = details?.results?.find(cityFind => cityFind.city === city);
                                                if (annualUSD) {
                                                    let annualCost = annualUSD.valueResult?.totalCost;
                                                    if (annualCost) {
                                                        infoToShow = formatNumber(annualCost);
                                                    }
                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number" key={`row5 ${index}`}>$ {infoToShow}</h5>
                                            })}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let addendum = ""
                                                let differenceFound = details?.results?.find(cityFind => cityFind.city === city);
                                                if (differenceFound) {
                                                    if (differenceFound.valueResult.bestOption) {
                                                        infoToShow = "Lowest Value"
                                                    } else {
                                                        if(differenceFound.valueResult.difference !== null){	
                                                            infoToShow = formatNumber(differenceFound.valueResult.difference) + addendum;
                                                            if (differenceFound.valueResult.typeOfDifference === "Percentage") {
                                                                addendum = " %"
                                                            }
                                                        }
                                                    }

                                                }
                                                return filtered.includes(index) &&
                                                    <h5 className="number red" key={`row6 ${index}`}>{infoToShow}</h5>
                                            })}
                                        </div>

                                    </Col>
                                </Row>
                                <Row className="no-gutters d-none d-lg-flex">
                                    <Col lg={3} className="fixed-container">
                                        <h5 className="first-spacer">INPUT</h5>
                                        <h5 className="side-text">Monthly Consumption Total GJL</h5>
                                    </Col>
                                    <Col lg={3} className="table-container">
                                        <div className="first-spacer"></div>
                                        <h5 className="number">{assumptions?.utilities?.gas}</h5>
                                    </Col>
                                </Row>
                                <Row className="no-gutters d-none d-lg-flex">
                                    <Col lg={3} className="fixed-container">
                                        <h5 className="table-title" style={{ padding: '10px 20px' }}>TARIFF REGION</h5>
                                        <h5 className="first-spacer">{yearToShow ?? new Date().getFullYear()} Tariff GJL</h5>
                                        <h5 className="side-text">Service</h5>
                                    </Col>
                                    <Col lg={9} className="table-container">
                                        <div style={{ display: 'flex' }}>
                                            {musts.sort((a, b) => a.municipality_name.localeCompare(b.municipality_name)).map((region, index) => 
                                                filtered.includes(index) &&
                                                <h5 key={`table2 ${region.datamexico_municipality_id}`} className="table-title" style={{ minWidth: '33%', padding: '10px 25px', textAlign: 'center' }}>{region.cre_gas_region}</h5>
                                            )}
                                        </div>
                                        <div className="table-header">
                                            {city_names.map((city, index) =>
                                                filtered.includes(index) &&
                                                <h5 key={`table3 ${city}`} className="city-title">{city}</h5>
                                            )}
                                        </div>
                                        <div className="row-number">
                                            {city_names.map((city, index) => {
                                                let infoToShow = "N/A"
                                                let cityFind = variableCosts?.find(cityFind => city === cityFind.cityName);
                                                if (cityFind && cityFind?.dataVariables?.gasCost?.averageCost !== null) {
                                                    infoToShow = formatNumber(cityFind.dataVariables.gasCost.averageCost);
                                                }
                                                if (filtered.includes(index)) {
                                                    return <h5 key={`table4 ${city}`} className="number">$ {infoToShow}</h5>;
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </Col>
                                    <h5 style={{ padding: '10px' }}>*AVG last 3 months of information available</h5>
                                </Row>

                                <section className="mobile-details-results-table d-lg-none">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Concept</th>
                                                {city_names.map((city, index) =>
                                                    filtered.includes(index) &&
                                                    <th key={`table5 ${index}`}>{city}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Subtotal MXP Monthly</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let subtotal = variableCosts.find(cityFind => cityFind.cityName === city);
                                                    if (subtotal) {
                                                        let subTotalAccess = subtotal?.dataVariables?.gasCost?.monthlyMxSubCost;
                                                        if (subTotalAccess) {
                                                            infoToShow = formatNumber(subTotalAccess);
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table6 ${index}`}>$ {infoToShow}</td>
                                                })}
                                            </tr>
                                            <tr>
                                                <td>VAT</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let vat = variableCosts.find(cityFind => cityFind.cityName === city);
                                                    if (vat) {
                                                        let vatAccess = vat.dataVariables?.gasCost?.vat;
                                                        if (vatAccess) {
                                                            infoToShow = formatNumber(vatAccess);
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table7 ${index}`}>$ {infoToShow}</td>
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Total MXP Monthly</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let total = variableCosts.find(cityFind => cityFind.cityName === city);
                                                    if (total) {
                                                        let totalAccess = total.dataVariables?.gasCost?.monthlyMxCost;
                                                        if (totalAccess) {
                                                            infoToShow = formatNumber(totalAccess);
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table8 ${index}`}>$ {infoToShow}</td>
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Estimated USD Monthly</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let monthlyCostUSD = variableCosts.find(cityFind => cityFind.cityName === city);
                                                    if (monthlyCostUSD) {
                                                        let monthlyUSD = monthlyCostUSD.dataVariables?.gasCost?.monthlyCost;
                                                        if (monthlyUSD) {
                                                            infoToShow = formatNumber(monthlyUSD);
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table9 ${index}`}>$ {infoToShow}</td>
                                                }
                                                )}
                                            </tr>
                                            <tr>
                                                <td>Estimated USD Annual Cost</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let annualUSD = details?.results?.find(cityFind => cityFind.city === city);
                                                    if (annualUSD) {
                                                        let annualCost = annualUSD.valueResult?.totalCost;
                                                        if (annualCost) {
                                                            infoToShow = formatNumber(annualCost);
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table10 ${index}`}>$ {infoToShow}</td>
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Difference from lowest value</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let addendum = ""
                                                    let differenceFound = details.results?.find(cityFind => cityFind.city === city);
                                                    if (differenceFound) {
                                                        if (differenceFound.valueResult?.bestOption) {
                                                            infoToShow = "Lowest Value"
                                                        } else {
                                                            if(differenceFound.valueResult?.difference !== null){	
                                                                infoToShow = formatNumber(differenceFound.valueResult.difference) + addendum;
                                                                if (differenceFound.valueResult.typeOfDifference === "Percentage") {
                                                                    addendum = " %"
                                                                }
                                                            }
                                                        }
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table11 ${index}`} className="red">$ {infoToShow}</td>
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>

                                <section className="mobile-details-results-table d-lg-none">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{textTransform: "uppercase"}}>input</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Monthly Consumption Total GJL</td>
                                                <td></td>
                                                <td>{assumptions?.utilities?.gas}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                                
                                <section className="mobile-details-results-table d-lg-none">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{textTransform: "uppercase" }}>tariff region</th>
                                                {city_names.map((city, index) => {
                                                    const mustCity = musts.find(must => must.municipality_name === city);
                                                    return filtered.includes(index) &&
                                                        <th key={`table12 ${index}`} style={{textAlign: "center"}}>{mustCity.cre_gas_region}</th>
                                                }
                                                )}
                                            </tr>
                                            <tr>
                                                <th>2023 Tariff GJL</th>
                                                {city_names.map((city, index) =>
                                                    filtered.includes(index) &&
                                                    <th key={`table13 ${index}`}>{city}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Service</td>
                                                {city_names.map((city, index) => {
                                                    let infoToShow = "N/A"
                                                    let cityFind = variableCosts?.find(cityFind => city === cityFind.cityName);
                                                    if (cityFind && cityFind?.dataVariables?.gasCost?.averageCost !== null) {
                                                        infoToShow = formatNumber(cityFind.dataVariables.gasCost.averageCost);
                                                    }
                                                    return filtered.includes(index) &&
                                                        <td key={`table14 ${index}`}>$ {infoToShow}</td>
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span style={{ padding: '10px' }}>*AVG last 3 months of information available</span>
                                </section>
                            </>
                        ) : (
                            <div className="no-record-wrapper">
                                <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                                <img src={infodanger} width="50px" height="50px" />
                            </div>
                        ))

            }

        </Container >
    )
}

export default GasCost;
import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useState, useEffect, useMemo } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const LandCost = ({ details, city_names, filtered, reportID, assumptions, variableCosts, allVariableCosts }) => {

    const propertiesToAccess = [
        "lowCost",
        "highCost",
        "averageSqftCost",
        "monthlyCost",
    ]


    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (assumptions && variableCosts) {
            setLoading(false);
        }
    }, []);

    const landCostCurrency = useMemo(() => allVariableCosts?.currency?.landCost ,[allVariableCosts])

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>Loading</div>
            ) : assumptions && variableCosts ? (
                <>
                    <Row className="d-none d-lg-block" style={{marginBottom:'0px'}}>
                        <Col lg={12} className="table-title land-header">
                            <h5 style={{fontWeight:'700'}}>Building size </h5>
                            <h5 style={{fontWeight:'700'}}>{formatNumber(+assumptions.land.sizeValue)} {assumptions.land.sizeType}</h5>
                        </Col>
                    </Row>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer" style={{background: 'rgba(7, 27, 57, 0.03)'}}>Concept</h5>
                            <h5 className="side-text">Low (USD)</h5>
                            <h5 className="side-text">High (USD)</h5>
                            <h5 className="side-text">Avg. (USD)</h5>
                            <h5 className="side-text">Monthly cost (USD)</h5>
                            <h5 className="side-text">Annual (USD)</h5>
                            <h5 className="side-text">Difference from Lowest Value(%)</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header" style={{background: 'rgba(7, 27, 57, 0.03)'}}>
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" style={{background: 'rgba(7, 27, 57, 0.03)'}} key={`${city}-${index}`}>{city}</h5>
                                )}
                            </div>
                            {propertiesToAccess.map((property, index) =>
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = variableCosts?.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let accessTo = cityData.dataVariables?.landCost?.[property];
                                            if (accessTo) {
                                                if (property === "lowCost" || property === "highCost") {
                                                    accessTo = !landCostCurrency || landCostCurrency === "USD" ? accessTo : accessTo / (assumptions?.exchangeRate ?? 20)
                                                }
                                                
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number">$ {dataToShow}</h5>
                                        )
                                    }
                                    )}
                                </div>
                            )}
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let dataToShow = "N/A";
                                    let cityData = details?.results?.find(cityFind => city === cityFind.city);
                                    if (cityData) {
                                        let accessTo = cityData?.valueResult?.totalCost;
                                        if (accessTo !== null) {
                                            dataToShow = formatNumber(accessTo);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number">$ {dataToShow}</h5>
                                    )
                                }
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let differenceToShow = "N/A";
                                    let addendum = "";
                                    let cityData = details?.results?.find(cityFind => city === cityFind.city);
                                    if (cityData) {
                                        if (cityData.valueResult?.bestOption ) {
                                            differenceToShow = "Lowest Value";
                                        } else {
                                            if(cityData.valueResult.typeOfDifference === "Percentage"){
                                                addendum = " %";
                                            }
                                            differenceToShow = cityData.valueResult.difference.toFixed(2) + addendum;
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number red">{differenceToShow}</h5>
                                    )

                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none" style={{marginBottom: "unset"}}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Building Size</th>
                                    <th></th>
                                    <th>{formatNumber(+assumptions.land.sizeValue)} {assumptions.land.sizeType}</th>
                                </tr>
                            </thead>
                        </table>
                    </section>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Low</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let accessTo = cityData.dataVariables?.landCost?.lowCost;
                                            if (accessTo) {
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td>$ {dataToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>High</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let accessTo = cityData.dataVariables?.landCost?.highCost;
                                            if (accessTo) {
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td>$ {dataToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Avg.</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let accessTo = cityData.dataVariables?.landCost?.averageSqftCost;
                                            if (accessTo) {
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td>$ {dataToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Monthly cost(USD)</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let accessTo = cityData.dataVariables?.landCost?.monthlyCost;
                                            if (accessTo) {
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td>$ {dataToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Annual</td>
                                    {city_names.map((city, index) => {
                                        let dataToShow = "N/A";
                                        let cityData = details.results.find(cityFind => city === cityFind.city);
                                        if (cityData) {
                                            let accessTo = cityData.valueResult?.totalCost;
                                            if (accessTo !== null) {
                                                dataToShow = formatNumber(accessTo);
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td>$ {dataToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Difference from Lowest Value(%)</td>
                                    {city_names.map((city, index) => {
                                        let differenceToShow = "N/A";
                                        let addendum = "";
                                        let cityData = details?.results?.find(cityFind => city === cityFind.city);
                                        if (cityData) {
                                            if (cityData.valueResult?.bestOption) {
                                                differenceToShow = "Lowest Value";
                                            } else {
                                                if(cityData.valueResult?.typeOfDifference === "Percentage"){
                                                    addendum = " %";
                                                }
                                                differenceToShow = cityData.valueResult?.difference?.toFixed(2) + addendum;
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td className="red">{differenceToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                <img src={infodanger} width="50px" height="50px" />
            </div>
            )
            }
        </Container>
    )
}

export default LandCost;